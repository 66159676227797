/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-03-25",
    versionChannel: "nightly",
    buildDate: "2024-03-25T00:06:06.434Z",
    commitHash: "9793e3968d3033c86a1a50ccc3188558e5c4b011",
};
